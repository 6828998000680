@font-face {
  font-family:'PWSchoolScript' ;
  src: url('./font/PWSchoolScript.ttf');
}

@font-face {
  font-family:'Abecedary-Regular' ;
  src: url('./font/Abecedary-Regular.ttf');
}

@font-face {
  font-family: 'Florence-Regular';
  src: url('./font/Florence-Regular.otf');
}

@font-face {
  font-family:"TTT-Regular" ;
  src: url('./font/TTT-Regular.otf');
}

@font-face {
  font-family:"HVD_Comic_Serif_Pro" ;
  src: url('./font/HVD_Comic_Serif_Pro.otf');
}


.App {
  text-align: center;
}

*,
body {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

color: black;
}

body{
  background-image: url(./images/181.jpg);
  
  background-size: 20%;
  background-repeat: repeat;

}
  /* background-color: rgba(127, 202, 205,0.5); */
  .bgBody{
  width: 100%;
  height: auto; 
  background-color: #01969b;
   background-color: rgba(1, 150, 155,0.5);

 
} 
li, a{
  text-decoration: none;
  cursor: pointer;
}
.divVide{
  width: 100%;
  height: 30vh;
}

button{
  border: 1px solid #01969b;

}
 
.essaiAbsolute{
  position: absolute;
  z-index: 0;
  background: none;
  width: 60px;
  background: none;
  right:45%;
  top:14%;
  transform: rotate(180deg);
  animation: MoveUpDown 1.5s linear infinite;
}

@keyframes MoveUpDown {
0%, 100% {
    top: 260px;
  }
  50% {
    top: 330px;
  }
}

.essaiAbsolute img{
  background: none;
}

.essaiAbsolute1{
  position: absolute;
  z-index: 0;
  background: none;
  width: 60px;
  background: none;
 right:35%;
 top:14%;
 animation: MoveUpDown 1.4s linear infinite;
}

.essaiAbsolute1 img{
  background: none;
}

.essaiAbsolute2{
  position: absolute;
  z-index: 0;
  background: none;
  width: 49px;
  background: none;
  top:14%;
  right:25%;
  animation: MoveUpDown 1.3s linear infinite;
}



.essaiAbsolute2 img{
  background: none;
}

/* .essaiAbsolute3{
  position: absolute;
  z-index: 3;
  background: none;
  width: 60px;
  background: none;
  top: 750px;
}

.essaiAbsolute3 img{
  background: none;
} */

.essaiAbsolute3{
  position: absolute;
  z-index: 0;
  background: none;
  width: 60px;
  background: none;
  top:14%;
  right: 15%;
  animation: MoveUpDown 1.2s linear infinite;
}

.essaiAbsolute3 img{
  background: none;
}

.essaiAbsolute4{
  position: absolute;
  z-index: 0;
  background: none;
  width: 60px;
  background: none;
  top:14%;
  right: 5%;
  animation: MoveUpDown 1.1s linear infinite;
}

.essaiAbsolute4 img{
  background: none;
}

/* .essaiAbsolute5{
  position: absolute;
  z-index: 3;
  background: none;
  width: 100px;
  background: none;
  bottom: 500px;
}

.essaiAbsolute5 img{
  background: none;
}

.essaiAbsolute6{
  position: absolute;
  z-index: 3;
  background: none;
  width: 100px;
  background: none;
  bottom: 250px;
}

.essaiAbsolute6 img{
  background: none;
}

.essaiAbsolute7{
  position: absolute;
  z-index: 3;
  background: none;
  width: 100px;
  background: none;
  bottom: 0;
} */

.essaiAbsolute7 img{
  background: none;
}


/* body{
  background-image: url('./images/body/landscape.png');
  background-repeat: no-repeat;
  background-size: cover;
} */
input, textarea{
  outline-style: none;
  background-color: white;
  padding:5px;
}


label{
  font-weight: bold;
  font-size:13px;
}
.imgTop{
  height:352px;
}
.upArrow, .ecoleAM, .svg-inline--fa.fa-facebook-square.fa-w-14.iconFacebook{
  width:auto;
height:30px;
  color: #7fcacd;


}

.profGard{
  display: flex;
}
 


.bla{
  width:auto;
  height:20px;
    color: #7fcacd;


  
}
.fa-facebook-square{
  font-size: 25px;
}

.upArrow{
cursor: pointer;
}

.divFooter1{
  display: flex;
  padding-top: 5%;
  line-height: 2em
}

.footerProject{
  position: relative;
  width:100%;
  padding:5%;
  border-radius: 50% 20% / 10% 40%;
background-color: white;
margin-top: 5%;
}


.dflexFooter{
  width:100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top:5%;
  border-top: 2px solid #01969b;
  box-shadow: 0px -2px 0px rgba(255, 255, 255, 0.5);

}



/* .liFooterFacebook{
  margin-left:5px;
} */

.divFooter{
  padding-top:5%;
  line-height: 2em
}

/* .dflexFooter div, .dflexFooter p, .dflexFooter h4, .dflexFooter li{

} */

.dflexFooter h4{
  font-weight: bold;
  font-size: 25px;
  
}

.ulLiensFooter{
  display: flex;
flex-wrap: wrap;
  list-style: none;
  justify-content: space-around;
}



.copyright{
  width: 60%;
  margin: auto;
  border-top: 1px solid white;
  height: 27px;
  
}

.copyright p{
  text-align: center;
  margin-top: 5%;
  line-height: 0;
  font-size: 12px;
}

.iconFacebook path{
  color:#01969b;
}



li.liFooterFacebook{
  width:auto;
  margin-right: auto;
padding: 12px;
}

.divUpArrow{
  width:20%;
  position: unset;
}
/* .divUpArrow.divFooter{

} */

.container1 {
  background-color: #7fcacd ;
  width: 1050px;
  min-height: 100vh;
  margin: auto;
  overflow: hidden;
  position: relative;
  border-right: 8px solid white;
border-left: 8px solid white;
/*   padding-bottom: 100px; */
}
a:hover{
  text-decoration: none;
  list-style: none;
}
.dflex {
  width: 100%;
  height: 55px;
  display: flex;
  margin: auto;
  background-color: #01969b;
  box-shadow: 1px 2px 11px 1px white;
}

.dflex1 {
  display: flex;
  justify-content: space-between;
  background-color: #01969b;
  width: 100%;
  align-items: center;
  margin-bottom: 0;

}
.dflex1 a {
  display: flex;
  justify-content: space-around;
  text-align: center;
  list-style-type: none;
  background-color: #01969b;
}

a.titreNavSub{
  display:block;
  text-align:left;
  display: block;
  font-size:9px;
  height: 30px;
}
.dflex1 .dernierLi {
  border-right: none;
}



/* a.titreNavSub.active.active1{
  width:auto;
} */

div.titreNav.active1{
  color: black;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  outline:none;
  cursor: pointer;
}


.titreNav {
  color: black;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;

}



.titreNav1 {
  color: black;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  border-right: 1px solid white;
  background: none;
  border: none;
}

/* .titreNav1:focus{
  color: white;
  font-weight: bold;
  font-size: 15px;
} */




a.titreNav{
  width: 100%;
  height: 100%;
  background-color: #01969b;
  border:none;
  text-decoration: none;
  /* border-right: 1px solid black; */

}



.imgResponsive img{
  display: none;
}

.notreEcole{
  background-color: #01969b;
}

.notreEcole:hover{
  color:white;
}

.titreNav:hover{
  text-decoration: none;
  color: white;
}

.titreNav1:hover{
  text-decoration: none;
  color: white;
}
.active {
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  outline:none;
}

.active2{
  border-radius: 50%;
width: 50px;
height: 50px;
border: 1px solid;
background: #eb6366;
font-size: 52%;
padding-top: 20%;
transform: scale(3);
color: white;
font-weight: bolder;
box-shadow: 2px 1px white;
}

.active3{
  border-radius: 50%;
width: 50px;
height: 50px;
border: 1px solid;
background: #8f4999;
font-size: 52%;
padding-top: 20%;
transform: scale(3);
color: white;
font-weight: bolder;
box-shadow: 2px 1px white;
}

.active4{
  border-radius: 50%;
width: 50px;
height: 50px;
border: 1px solid;
background: #ffe861;
font-size: 52%;
padding-top: 20%;
transform: scale(3);
color: white;
font-weight: bolder;
box-shadow: 2px 1px white;
}

.active5{
  border-radius: 50%;
width: 50px;
height: 50px;
border: 1px solid;
background: #34c924;
font-size: 52%;
padding-top: 20%;
transform: scale(3);
color: white;
font-weight: bolder;
box-shadow: 2px 1px white;
}

.active6{
  border-radius: 50%;
width: 50px;
height: 50px;
border: 1px solid;
background: #df1d25;
font-size: 52%;
padding-top: 20%;
transform: scale(3);
color: white;
font-weight: bolder;
box-shadow: 2px 1px white;
}

.tache,.guihaut, .guibas{
  display: none;
}

.styleBurger{
  display: none;
}

.barreLiens{
  width: 100%;
  min-height: 100px;
  background-color : #c35884;
}

.barreLiens1{
  width: 100%;
  min-height: 100px;
  background-color : #c35884;
}


.titreMatPrim{
  font-size: 27px;
text-transform: full-width;
padding-bottom: 2%;
}

/* .titrePage{
  display: flex;
  height: 100px;
  align-items: center;
  background: none;
  position: absolute;
left: 30%;
top: 189px;
} */

.titrePage{
  font-size: 50px;
  text-align: center;
  background-color: #c35884;
}

.titrePage h1{
font-size: 50px;
text-align: center;
font-family: "HVD_Comic_Serif_Pro";
padding-bottom: 19px;
margin-bottom: 0;
}

.titrePage1{
  background: none;
  text-align: center;background-color: #c35884;
  margin-top: 0;
}

.titrePage1 h1{
font-size: 50px;
text-align: center;
font-family: "HVD_Comic_Serif_Pro";
}

h1{
  background: none;
  padding-top: 18px;
  padding-left:10px;
  font-family: 'PWSchoolScript';
}

ul.dropdownMenu{
  
width: 20%;
position: absolute;
  margin-left: -1px;
  list-style: none;
  z-index: 3;
background-color: #01969b;
padding:10px;
border-right: none;
text-align: left;
}



.btnConnection{
  width: 150px;
background: white;
border-radius: 25px;
position: absolute;
right:17px;
top:107px;
border: 1px solid #01969b;
font-weight: bold;
padding: 4px;
position: absolute;
z-index: 2;
}

.titreNavSub.noBorder{
  border-bottom: none;
  }

a.titreNavSub{
  position: relative;
  top:5%;
  color: black;
  font-size: 9px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
margin-top: 10px;
margin-left: 5px;
border-left:none;
padding: 5px;
margin-bottom: 10px;
}

.titreNavSub:hover{
  
  background-color: white;
  text-decoration: none;
  color: black;
}

.carousel-indicators li{
  display: none;
}

.dflex3{
  margin-top: 100px;
display: flex;

}

.accueilFlex{
  display: flex;
justify-content: space-around;
width: 90%;
margin: auto;
align-items: center;
}

.accueilGroupe{
  width: 392px;
  display: block;
  border: 2px solid white;
}

.agendaContent{
  background: none;
}

.calendar{
  width: 329px;
  height: auto;
  margin: 0;
}

.jourSem{
  text-align: center;
  background: none;
}

.row1 {
  display: flex;
  flex-direction: row;
width: 100%;
  align-items: center;
}



.month-display{
  height: 47px;
  background-color: white;
}

.month-label{
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  background-color:white;
}

.leftRight{
  background-color: white;
  margin: 5px;
  cursor: pointer;
}

.day{
  width: 47px;
  background-color: #01969b;
  border: 1px solid white;
  font-weight: bold;
  height: 47px;
}
.day-names .day{
  display: flex;
  align-items: center;
  margin: auto;
}
.numJour{
  font-size: 12px;
  text-align: right;
  background: none;
  padding-right: 5px;
}

.today{
  background-color: #c35884;
}

.different-month{
  opacity: 0.5;
}
.caret1{
  width:5%;
background: bottom;
color:black;
}





svg.svg-inline--fa.fa-user.fa-w-14{
  width: 25px;
  background: none;
  border: 2px solid white;
  padding: 3%;

}

svg.svg-inline--fa.fa-user.fa-w-14 > path{
  color:black;
}

.caret, .caret2{

background: bottom;
color:black;
margin-left:10px;
}
.fa-caret-down{
  background-color: #01969b;
  width: 0.625em;
}

.philosophie{
  display: none;
}

.aIcon-flex {
  width: 100%;
  background: none;
  display: flex;
}

.aIcon-flex1{
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 5%;
  background: none;
  display: flex;
}
.aIconBorder{
  border-right: 1px solid #01969b;
}

.aIcon.aIconBorder p{
  font-size: 15px;
}
.rond{
  width: 100px;
  height: 100px;
  border-radius: 50px;
  align-items: center;
  margin: auto;
  background-color:white;
  box-shadow: 5px 5px 5px black;
  

}

a.aIcon:hover{
  transform: scale(1.2);
  transition: transform 330ms ease-in-out;
}

.aIcon{
 display: block;
 text-align: center;
 padding: 2%;
}

.icon {
  width: 60px;
  height: 60px;
  transform: translatey(33%);
  background: none;
  margin: auto;
}

.titreIcon{
  margin-top: 20px;
  background: none;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
}

.aIcon:hover{
  text-decoration: none;
}

.planEcole{
  width: 90%;
  margin: auto;
  margin-top: 5%;
}

.planEcole iframe{
  border: none;
}
.adressEmail{
  text-decoration: none;
  color: black;
}

.adressEmail:hover{
  text-decoration: none;
  color: #01969b;
}

.logoSjb{
  width: 111px;
  position: absolute;
  z-index: 1;
  margin-top: 72px;
  background: none;
  margin-left: 10px;
}

.dflex li a.log{
  width: 50px;
}

.svg-inline--fa{
  background: none;
}

/* .svg-inline--fa :hover{
 color: white;
} */
/* .titreNav4:active{
color:red;
} */
.msg {
  display: none;
}

.msg.msgAppear {
  display: block;
}
.row{
  align-items: center;
}

.dflexGalleryDiv{
  padding:25px;
  margin-top: 120px;
}

.dflexImg{
width: 90%;
margin:auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
/*   border:13px solid white; */
  min-height:350px;
  border-radius:9px;
  margin-bottom:5%;
  margin-top:5%;

}

.dflexImgPermanente{
  width: 45%;
  padding: 10px;
  border-radius:9px;
  margin:auto;
  margin-top:10%;
  background-color: #01969b;
margin-bottom: 5%;
  border:4px solid #01969b;
  box-shadow: 3px 3px 3px black;
}


.dflexImgPermanente2{
  display: flex;
  width: 90%;
  padding: 10px;
  border-radius:9px;
  margin:auto;
  margin-top:5%;
  background-color: #fff84d;
  border:4px solid #fff84d;
  box-shadow: 3px 3px 3px black;
}

.dflexImgPermanente1 {

  width: 90%;
  padding: 10px;
  border-radius: 9px;
  margin: auto;
      margin-top: auto;
  margin-top: 5%;
  background-color: #fdcde1;
  border: 4px solid #fdcde1;
  box-shadow: 3px 3px 3px black;
}


/*WIDGETS*/


.dflex4{
background: none;
width: 90%;
align-items: center;
margin: auto;
margin-bottom: auto;
margin-bottom: 0;
top: 501px;
left: 729px;
}

.dflex4 li {
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  list-style-type: none;
  background-color: #01969b;
  width: 250px;
  height: 40px;
  margin: 10px;
  border: 1px solid white;
  cursor: pointer;
}

.dflex4 li a{
  border:none;
}

/*ACTIVITES*/

.dflexavertissement{
  width:80%;
  display:flex;
  align-items:center;
margin: auto;
  padding-top:2%;
  margin-bottom: 5%;
  background: white;
display: none;
}

.flexactivite{
  display: flex;
  
}

.dflexavertissement img{
  width:62px;
  background:#ffe861;
  padding:5px;
  background: #ff4f19;
}

.dflexavertissement p{
text-transform: uppercase;
font-weight: bold;
margin-top: 20px;
background: #ff4f19;
color:white;
}
 .dflexavertissement p span{
 /*  text-transform:initial; */
  background: #ff4f19;
  color:white;
/*   font-size: 14px; */
} 

.buttonAddActivite {
  width: 65%;
  height: 40px;
  display: flex;
  flex-direction: column;
  margin-left: 50%;
  justify-content: center;
  border-radius: 9px;
  margin: auto;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: white;
  background: #01969b;
  margin-top:5%;
  margin-bottom:5%;
}

.buttonAddActivitePermanente {
  width: 65%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  margin: auto;
  text-transform: uppercase;
  font-size: x-small;
  font-weight: bold;
  color: #01969b;
  background: white;
  margin-top:5%;
  margin-bottom:5%;
  border: 1px solid;
box-shadow: 3px 3px 3px;
}

.buttonAddActivite.ajouter { 

  border-radius: 50%;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: white;
  background: #01969b;
  margin-bottom: 5%;
  width: 200px;
  height: 200px;
  border: 6px solid white;
  font-weight: bold;
}

.buttonAddActivite1.ajouter { 
  margin: auto;  display: flex;
  padding: 5px;
  align-items: center;
  text-transform: uppercase;
  font-size: 13px;
  color: black;
  background: white;
  margin-bottom: 5%;
  width: 200px;
  font-weight: bold;

 cursor: pointer;
}

.addActivitePlus{
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.divAddActivite{
  width: 85%;
  background: none;
  display: flex;
  justify-content: space-around;
  margin: auto;
  border-bottom: 1px solid white;
  margin-bottom:5%;
  margin-top: 5%;
}

.divAddStudent{
  background: none;
  margin-top:5%;
}


input#id{
  width:20%;
  margin-left:5%;
}
.parenthèses{
  font-size:9px;
}

.fileActivite{
  background: none;
}

.dlexRef{
  display:flex;
}

p.dflexRefP{
  margin-bottom:0;
  width: 10%;
font-size: x-small;
border: 1px solid;
text-align: center;
background-color: white;
}

/* .buttonAddActivite:hover{
  color:#75bc79;
} */
.dlexRefPermanente{
  display: flex;
  background:none;
}
div.dflexAddEditDelete div{
  padding-right:1%;
  padding-left: 1%;
}

.inputDelete{
  width: 20%;
margin-left: 5%;
}

.dflexAddEditDelete{
  display:flex;
  width:98%;
  margin:auto;
  margin-top:2%;
  background: none;
}

.dflexAddEditDeletePermanente{
  background:none;
  display:flex;
  margin-top: 22px;
}

.dflexAddEditDeletePermanente div{
  background:none;
}
.componentActivite{
 margin-top:5%;
}

div.componentActivite.add{
  width: 50%;
  margin: auto;
}

div.componentActivite.addStudent{
  width: 50%;
  margin: auto;
}

.divformAdd{
  width: 100%;
background: white;
border-radius: 25px;
border: 1px solid #01969b;
padding: 20px 20px 10px 20px;
margin-bottom: 20px;
}

.formAdd{
  width: 80%;
  margin: auto;
}
form{
  padding:5px;

/*   border:4px solid #75bc79; */
/*   padding-bottom: 5%; */
}
.BgActivitesList{
  padding-top:5%;
  padding-bottom: 5%;
/*   background-image: url("../src/images/dessins_enfants/dessin_enfant1.jpg");
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center; */
}

.imgActivite {
  margin-left: 50px;
  width:37%;
  height:245px;
  display:flex;
}

.imgActivite img{
  border-right:4px solid white;
  padding-right:10%;
  border-radius: 9px 0 0px 9px;
  background: #7fcacd;
}


.imgActivitePermanente {
width: 50%;
  margin: auto;
  border-style: none;

}

.imgActivitePermanente img{
border:3px solid white;
  width: 250px;
  height: 250px;
  object-fit: cover;
  padding:2%;
  background-color: white;
}

.textActivite{
  margin-left: 50px;
}

.dateActivite {
  margin: auto;
  width: 100px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  writing-mode: vertical-rl;
text-orientation: upright;
}

.titreTextActivite{
  text-transform: uppercase;
  font-weight: bold;
}

.titreTextActivitePermanente{
 
  text-align: center;
  
  font-size: 18px;
  font-weight:bolder;
  margin-bottom:0;
  font-family: 'Josefin Sans', sans-serif;
}

.descriptionActivite{
width: 100%;
height: 100px;
font-size: 12px;
}

.titreTextActivitePermanente2{
  margin: auto;
  width: 16%;
  display: flex;
  justify-content: center;
  background-color: white;
  font-size: 13px;
  margin-top: 15px;
}

.titreTextActivitePermanente2bis{
  margin:auto;
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top:10%;
  margin-bottom:5%;
  background-color: white;
  font-size: 13px;
}

div.textActivitePermanente p{
  background:white;
  border:5px solid white;
  font-size:13px;
}

.textActivitePermanente{
  width: 100%;
  background:white;
  border:5px solid white;
}
.numRef{
  margin-left: 79%;
  width: 8%;
  text-transform: lowercase;

}
.searchActivites{
  position: absolute;
top: 606px;

}

.dflexTotAct{
  width: 100%;
  display: flex;
  flex-wrap: wrap;

}

.searchActiviteForm{
  width:30%;
margin-left: auto;
border:none;
}

.dflexSearch{
  display: flex;
  align-items: center;
}
.dflexSearch input{
  width:100%;
  background:white;
  padding:5px;
  border-radius:9px;
}

.dflexSearch span{width:16%;

  margin-left:5.5%;
}

.dflexSearch1{
  display: flex;
  align-items: center;
  margin-top: 5%;
}
.dflexSearch1 input{
  width:100%;
  background:white;
  padding:5px;
  border-radius:9px;
}

.dflexSearch1 span{width:16%;

  margin-left:5.5%;
}

.descriptionActivites{
  margin-top: 5%;
}

/*PISCINE*/

.piscineLi{
  margin-left:129px;
  font-weight: initial;

}

.piscineUl{
  font-weight: bold;

}

.piscineStrong{
  font-style: italic;
}

/*LIVRET ROI*/

.imgLivret {
  width: 50%;
    margin: auto;
    border-style: none;
    margin-top: 28px;
  
  }
  
  .imgLivret img{
  border:3px solid white;
display: block;
    object-fit: cover;
    padding:2%;
    background-color: white;
  }

  .keyLivret{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .blockLivret{
   width: 240px;
   min-height: 240px;
margin: auto;
    margin-top: auto;
border: 1px solid white;
margin-top: 5%;
padding: 10px;
border-radius: 25px;
background: white;
cursor: pointer;
  }



/*PAGE COMPTE*/

.barreNavAbs{
  width: 100%;
  height: 37px;
  position: absolute;
  background-color: #01969b;
  top: 0;
}

.dflexCompte{
  display: flex;
  text-decoration: none;
  list-style: none;
  text-transform: uppercase;
  padding-top: 1%;

}

.dflexCompte1{
  display: flex;
  justify-content: space-between;
  margin: auto;
align-items: center;
  text-decoration: none;
  list-style: none;
  text-transform: uppercase;
  background-color: #01969b;
  height: 55px;
  border: 1px solid white;
box-shadow: 1px 2px 11px 1px white;
}

/* ul.dflexCompte1 li.liCompte button.titreNav, 
ul.dflexCompte1 li.liCompte a.titreNav {
  padding-top:5%;
} */

.noBackg{
  background-color: #01969b;
  cursor: pointer;
  border:none;
  text-transform: uppercase;
}

.dflexCompte li.liCompte{
  background: #01969b;
  text-decoration: none;
  text-align: center;
  border-right:1px solid white;
}

.dflexCompte li.liCompte:last-child{
border:none;
}

.dflexCompte1 li.liCompte{
  background: #01969b;
  text-decoration: none;
  text-align: center;
  border-right:1px solid white;
}



.dflexCompte1 li.liCompte:last-child{
border:none;
}


/*PAGE ACTIVITE PONCTUELLES*/

/* div .dflexImg:nth-child(even) {
  background: #f99a9c;
}
 */
.textActivite {
  background: none;
}

/* .textActivite p{
  background:none;
} */

/* div .dflexImg:nth-child(odd) {
  background:rgb(170, 151, 255)
} */

h2{
  background: none;
}

section .espace:nth-child(even) {
  background: #f99a9c;
  height: 10px;

}

section .espace:nth-child(odd) {
  background:rgb(170, 151, 255);
  height: 10px;

}


/*PAGE CONNEXION*/

.connectDiv {
  position: absolute;
right: 17px;
top: 117px;
border: 1px solid #7fcacd;
  width: 300px;
  height: auto;
  margin: auto;
  background: white;
  padding: 20px 20px 10px 20px;
  border-radius: 25px;
  margin-top: 5%;
  z-index:2;
}

.backWhite{
  background-color: white;
  font-weight: bold;
  text-transform: uppercase;

}

.btnLogin{
  display: flex;
  margin: auto;
  font-weight: bold;
  text-transform: uppercase;
  background-color: white;
  width: 50%;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10px;
}

.btncancel {
  background: none;
  border: none;
  position: absolute;
  width: 28px;
  height: auto;
  top: 11px;
  right: 10px;

}

.btncancel1 {
  display: block;
  background: none;
  border: none;
  position: absolute;
  width: 40px;
  height: auto;
  right: 65px;
  padding: 6px;
}

.btncancel2{
  background: none;
border: none;
position: absolute;
width: 60px;
height: auto;
right: 21%;
padding: 6px;
z-index: 1;
margin-top: 7px;
}

.cancel{
width: 100%;
display: block;
}

.iptConnexion{

  background: white;
}

/*LA DIRECTION*/

.divTextDirection{
  width: 700px;
  height: auto;
  background-color: white;
  margin: auto;
  padding: 20px;
  margin-top: 50px;
}

.divTextDirection h2{
  margin-bottom: 20px;
  color:#01969b;
  font-weight: bold;
}

.divTextDirection p{
  background-color: white;
  font-size: 15px;
}

.imgDirection {
  width: 120px;
  height: 120px;
  position: absolute;
  background: none;
  top: 347px;
  left: 848px;
  
}

.imgDirection img{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid #c35884;
  object-fit: cover;
}



.formAddStudent{
width:75%;
margin:auto;
border:none;margin-top:5%;
padding-top:5%;
padding-bottom:5%;
}

.dflexAddStudent{
  display:flex;
  justify-content: space-between;
  margin: auto;
/*   background-color: white; */
  padding-left:3%;
}

.inputAddStudent{
width:90%;
background-color: white;
margin-right:18px;
outline-style: none;
background-color: white;
padding:5px;
}

.labelAddStudent{
  display:flex;
  flex-direction: column;

}

/* .dflexAddStudent1 div{
   background-color: white;
}  */

.dflexAddStudent1{
margin-top : 5%;
margin-bottom:5%;
/* background-color: white; */
padding-left:3%;
display: flex;
align-items: center;
}

.buttonAddStudent{
  width: 65%;
  height: 40px;
  display: flex;
  flex-direction: column;
  margin-left: 50%;
  justify-content: center;
  border-radius: 9px;
  margin: auto;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: #c35884;
  background: white;
}

.buttonAddStudent:hover{
  color:#75bc79;
}

.selectClasse{
  text-align: center;
}

.ulUserList{
  width: 100%;
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin-top: 10px;

text-transform: uppercase;
font-weight: bold;
font-size: 13px;

}

.ulUserList li{
  border-right: 1px solid white;
  padding-left:15px;
}

.ulUserList li:first-child{
  border-right:none;
  width:25%;

}

.ulUserList li:last-child{
  border-right:none;
}

.ulMapUserList{
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: left;
  list-style: none;
  padding:0;
}
.divStudent{
  margin:auto;
  margin-top:5%;
  margin-bottom:0;width: 100%;
  margin-left: 6%;
  
}
.ulMapUserList li{

  padding-left: 15px;
  border-bottom:2px solid #ccc;
  padding-top: 10px;
  background-color: white;
}

.ulMapUserList li:first-child{
 width:25%;

}
/*P3-P4*/
.primaire{
  margin-top:15%;
}

.titrePrimaire{
  margin:auto;
    margin-bottom: auto;
width: 100%;
text-align: center;
margin-bottom: 18px;
position: absolute;
top: 90px;
font-weight: inherit;
}

.titrePrimaire1{
  background: rgba(255,255,255,0.7);
  margin: auto;
margin-bottom: auto;
width: 100%;
text-align: center;
position: absolute;
top: 187px;
padding-bottom: 15px;
font-family: 'Roboto', sans-serif;
text-transform: uppercase;
font-weight: inherit;
}

.borderImgPhotoP1{
  width: 250px;
  height: 250px;
  border: 1px solid white;
  border-radius: 50%;
  top: 400px;
  left: 7%;
  position: absolute;
  border:4px solid #8f4999;
}

.borderImgPhotoP3{
  width: 250px;
  height: 250px;
  border: 1px solid white;
  border-radius: 50%;
  top: 400px;
  left: 7%;
  position: absolute;
  border:4px solid #eb6366;
}

.borderImgPhotoP5{
  width: 250px;
  height: 250px;
  border: 1px solid white;
  border-radius: 50%;
  top: 400px;
  left: 7%;
  position: absolute;
  border:4px solid #ffe861;
}


.imgPhotoProf{
  width: 250px;
  height: 250px;
  /* border:1px solid white; */
  border-radius: 50%;
  background-image: url(./images/profs/caroline.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  border: 17px solid white;
}

.imgPhotoProf1{
  display: block;
  margin-top:-201px;
  margin-left:-87px;
  position: relative;
  width: 250px;
  height: 250px;
  /* border:1px solid white; */
  border-radius: 50%;
  border: 17px solid white;
  object-fit: cover;
  box-shadow: -3px 3px 3px 3px #8f4999;
}

.imgPhotoProf3{
  display: block;
  margin-top:-201px;
  margin-left:-87px;
  position: relative;
  width: 250px;
  height: 250px;
  /* border:1px solid white; */
  border-radius: 50%;
  border: 17px solid white;
  object-fit: cover;
  box-shadow: -3px 3px 3px 3px #eb6366;
}

.imgPhotoProf5{
  display: block;
  margin-top:-201px;
  margin-left:-87px;
  position: relative;
  width: 250px;
  height: 250px;
  /* border:1px solid white; */
  border-radius: 50%;
  border: 17px solid white;
  object-fit: cover;
  box-shadow: -3px 3px 3px 3px #ffe861;
}

.imgPhotoProf6{
  display: block;
  margin-top:-201px;
  margin-left:-87px;
  position: relative;
  width: 250px;
  height: 250px;
  /* border:1px solid white; */
  border-radius: 50%;
  border: 17px solid white;
  object-fit: cover;
  box-shadow: -3px 3px 3px 3px #34c924;
}

.imgPhotoProf7{
  display: block;
  margin-top:-201px;
  margin-left:-87px;
  position: relative;
  width: 250px;
  height: 250px;
  /* border:1px solid white; */
  border-radius: 50%;
  border: 17px solid white;
  object-fit: cover;
  box-shadow: -3px 3px 3px 3px #df1d25;
}

.imgPhotoProf8{
  display: block;
  margin-top:-201px;
  margin-left:-87px;
  position: relative;
  width: 250px;
  height: 250px;
  /* border:1px solid white; */
  border-radius: 50%;
  border: 17px solid white;
  object-fit: cover;
  box-shadow: -3px 3px 3px 3px #0344c4;
}

.imgPhotoProf9{
  display: block;
  margin-top:-201px;
  margin-left:-87px;
  position: relative;
  width: 250px;
  height: 250px;
  /* border:1px solid white; */
  border-radius: 50%;
  border: 17px solid white;
  object-fit: cover;
  box-shadow: -3px 3px 3px 3px #ddbe9e;
}

.imgPhotoProfGarderie{
  display: block;
  width: 250px;
  box-shadow: -3px 3px#c35884;
  border: 17px solid white;
  object-fit: cover;
}



.imgPhotoProf10{
  display: block;
  margin-top:-201px;
  margin-left:-87px;
  position: relative;
  width: 250px;
  height: 250px;
  /* border:1px solid white; */
  border-radius: 50%;
  border: 17px solid white;
  object-fit: cover;
  box-shadow: -3px 3px 3px 3px #1f71a0;
}



/* .imgPhotoProf2{
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-image: url(./images/profs/fanny.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  border: 17px solid white;
} */
.point{

  position: absolute;
width: 616px;
top: 521px;
}

.point1{
  position: absolute;
width: 28%;
top: 54%;
left: 10%;
}
.dflex2 {
  position: relative;
  display: flex;
  justify-content: space-around;
  background: none;
  width: 90%;
  align-items: center;
  margin:auto;
  margin-bottom: 0;

}
.dflex2 li {
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  list-style-type: none;
  background-color: #01969b;
  width: 100px;
  height: 100px;
  margin: 10px;
  border: 6px solid white;
  border-radius: 50%;
  cursor: pointer;
}

.dflex2 li a{
  border:none;
}


.descClasseP1{
  width: 90%;
  height: auto;
  margin: auto;
      margin-top: auto;
      margin-left: auto;
  background-color: white;
  margin-top: 19%;
box-shadow: 8px 8px #8f4999;

}

.descClasseP3{
  width:90%;
  height:auto;
  margin: auto;
  background-color: white;
  margin-top: 19%;
box-shadow: 8px 8px #eb6366;

}

.descClasseP5{
  width:90%;
  height:auto;
  margin: auto;
  background-color: white;
  margin-top: 19%;
box-shadow: 8px 8px #ffe861;

}

.descClasseM1{
  width:90%;
  height:auto;
  margin: auto;
  background-color: white;
  margin-top: 19%;
  margin-bottom: 7%;
box-shadow: 8px 8px #34c924;

}

.descClasseGarderie{
  width:90%;
  height:auto;
  margin: auto;
  background-color: white;
  margin-top: 5%;

}

.descClasseM2{
  width:90%;
  height:auto;
  margin: auto;
  background-color: white;
  margin-top: 19%;
box-shadow: 8px 8px #df1d25;

}

.descClasseNdlr{
  width:90%;
  height:auto;
  margin: auto;
  background-color: white;
  margin-top: 19%;
box-shadow: 8px 8px #0344c4;

}

.descClasseAdaptation{
  width:90%;
  height:auto;
  margin: auto;
  background-color: white;
  margin-top: 19%;
box-shadow: 8px 8px #ddbe9e;

}

.descClasseGym{
  width:90%;
  height:auto;
  margin: auto;
  background-color: white;
  margin-top: 19%;
box-shadow: 8px 8px #1f71a0;

}

.descClasseP1 p,.descClasseP3 p, .descClasseP5 p, .descClasseM1 p, .descClasseM2 p, .descClasseNdlr p, .descClasseAdaptation p, .descClasseGym p, .descClasseGarderie p{
  background-color: white;
  padding:10%;
  padding-left: 22%;
padding-top: 0;
font-size: 15px;
}



.nosClassesP3{
  position: relative;
  margin:auto;
  width: 85%;
margin: auto;
margin-bottom: 5%;
}

.fa-phone-flip{
  width: 11px;
}

.etoileProf{
  position: absolute;
  left: 12.5%;
  top: 663px;
  background: none;
}

.etoileProf img{
  background: none;
width: 28%;
}

.nomProf{
  width: 100%;
  position: relative;
  font-size: 66px;
  padding-left: 22%;
  font-weight: 100;
}

.nomProf1{
  width: 100%;
  position: relative;
  font-size: 60px;
  padding-left: 22%;
  font-weight: 100;
}

.nomProfGarderie{
  width: 100%;
  font-size: 35px;
  padding-left: 22%;
  font-weight: 100;
}

#formatted {
  white-space: pre-line;
}

.ligneDroite{
  width: 205px;
  height: 5px;
  background-color: white;
  position: absolute;
  left: 207px;
  top: 46%;
}

.ligneDroite1{
  width: 205px;
  height: 5px;
  background-color: white;
  position: absolute;
  left: 517px;
  top: 46%;
}

.ligneDroite2{
  display: block;
  width: 356px;
  height: 5px;
  background-color: white;
  position: absolute;
  top: 46%;
}

/*NOTE*/
.noteContainer{
  width:90%;
  margin:auto;
  padding:5%;
}

.formNote{
  padding:5%;
}

.labelNote{
  margin-top:5%;
}

.inputNote{
  width: 50px;
margin-left: 5%;
height: 50px;
}

.buttonAddNote{

    width: 283px;
    display: flex;
    justify-content: center;
    border-radius: 9px;
    margin: auto;
        margin-top: auto;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background: #01969b;
    padding: 2%;
    margin-top: 5%;

}

.note{
  width: 16em;
  min-height: 14em;
  background: white ;
  position: relative;
  filter: drop-shadow(3px 3px 10px #01969b);
  transform: rotate(7deg) translateX(-50%);
  top: 50px;
  left: 50%;
}
.note:before{
    content: '';
    position: absolute;
    right: 2em;
    bottom: -2em;
    left: 0;
    border: 1em solid white;
    z-index: -1;
}
 .note:after{
    content: '';
    position: absolute;
    right: 0;
    bottom: -2em;
    border-width: 2em 2em 0 0;
    border-style: solid;
    border-color: wheat transparent;
    filter: drop-shadow(-4px 5px 9px rgba(0,0,0,0.5));
 }
.noteDesc{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-transform: uppercase;
  background: transparent;
  width: 90%;
  height: 5.8em;
  padding: 0;
  border: 0;
  overflow: hidden;
  letter-spacing: -1px;
  outline-style: none;
  resize: none;
}

.noteDesc p{
  text-transform:initial;
  font-size: 15px;
}

.noteText{
  padding-left: 65%;
font-size: 24px;
padding-top: 5%;
background: none;
font-weight: bold;
border-bottom: 1px solid wheat;
}

div.noteDesc p{
  background: none;
  padding-left:5%;;
}

/*ALBUMPHOTO*/

.albumPhotos{
  width:100%;
}
.totAlbumDflex{
  width: 100%;
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
  margin-top: 5%;
}

.albumDflex{
width: 75%;
min-height: 290px;
border: 10px solid white;
padding:10px;
margin: auto;
margin-bottom: 10px;
background-color: #ccffee;
}

.galerieImageFirst{
  width: 90%;
height: 200px;
margin: auto;
box-shadow: 3px 3px 3px lightslategray;
margin-top: 7%;
}

.galerieImageFirst img{
  object-fit: cover;
width: 100%;
height: 100%;
}


.albumDflex p{
  font-weight: bold;
  font-size:14px;
  text-align: center;

}

p.photoDescription{
  font-weight: unset;
  font-style: italic;
  font-size: 12px;
}

.dflexButtonPhoto{
  display:flex;
  justify-content: space-around;
  margin-top: 15%;
  padding-bottom:7%;
}

.divPhotoBtn{
  margin-top:10%;
}

.photoBtn{
  width: 40px;
height: 40px;
background:none;
border: none;

}

.visionPhoto{
  width: 90%;
margin: auto;
margin-bottom: 5%;
}

.photoBtnAjouter{
  border: 1px solid #01969b;
width: 40px;
height: 40px;
border-radius: 50%;
background: #01969b;
}

.titreDescGalerie p{
  font-weight: bold;
margin-left: 44px;
}

.photoDescription{
  padding: 57px;
  padding-top: 0;
  padding-bottom: 0;
}

.imgAjouterGalerie{
  width: 30px;
height: auto;
padding-left: 4px;
margin: auto;
}


.dflexTotMapGalerie{
width: 33%;

}

.fa-times-circle{
  font-size: 45px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 3px 3px black;
  border: 2px solid white;
}

.fa-times-circle path{
  color: #fc5119;
}

.fa-plus-circle{
  font-size: 45px;
  background-color: white;
border-radius: 50%;
box-shadow: 3px 3px black;
border: 2px solid white;
}

button.photoBtnGalerie{
  background: white;
text-align: center;
font-weight: bold;
font-size: 78%;
border: none;
padding: 3%;
color: #01969b;
border-radius: 17px;
box-shadow:3px 3px 3px #01969b;
}
.fa-plus-circle path{
  color: #b6a801;
}

div.itemGaleriePhoto.carousel-item{
margin-top: 20%;

}

/* .imgGaleriePhoto{

} */

/* div.carousel-inner{
  width: 40%;
  margin: auto;
} */


div.carouselGaleriePhoto.carousel.slide{
  width: 60%;
  margin: auto;
  height: 500px;
  background-color: black;
  
}

.itemGaleriePhoto .carousel-item{
  width: 100%;
  height: auto;
}

.d-block.imgGaleiePhoto{
  width: 250px;
  max-height: 300px;
  margin: auto;
  object-fit: contain;
}

a.carousel-control-next, a.carousel-control-prev{
  width: 50px;
  height: 50px;
  background: #01969b;
  border-radius: 25%;
  margin: 37px;
  margin-top: 30%;

}

.formAddGalerie{
  width:75%;
  margin:auto;
  border:none;
  padding-top:5%;
  padding-bottom:5%;
  }
  
  .h2Galerie{
    margin-top:5%;
    margin-left: 5%;
  }

  .buttonAddGalerie{
    width: 65%;
    height: 40px;
    border-radius: 9px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    color: #c35884;
    background: white;
    margin-top:5%;
    width: 100px;
    margin-left: 77%;
  }

  .barreLiensGalerie{
    width: 100%;
    min-height: 100px;
    background-color : #c35884;
    position: absolute;
top: 411px;
  }

/*   .carouselGalerie{
    background-color: black;
    padding-top: 10px;
  } */

/*REPAS*/
.BgRepasList{
  display:flex;
  width:100%;
}

.imgRepas{
  width: 100%;
height: auto;
}

.divRepas{
  padding:5%;
}

/* .repasTot{
  background-image: url('./images/nourriture.svg');
}
 */
div.divRepas h4{
  font-size:21px;
  padding-top:5%;
}

.activeText{
  color:red;
}

p.dflexRefP{
  margin-left: 3%;
  padding-top:2%;
}

.dflexRefPermanente{
  position: relative;
left: 29%;
background: none;
}

div.toggle{
  background-color: #01969b;
}
/* .ulMapUserList li:nth-child(odd){
background-color: white;
}

.ulMapUserList li:nth-child(even){
  background-color: #ccc;
} */

@media screen and (min-width:200px) and (max-width:1049px){

  body{
    background-image: url(./images/181.jpg);
    
    background-size: 100%;
    background-repeat: repeat;
  
  }
    /* background-color: rgba(127, 202, 205,0.5); */
    .bgBody{
    width: 100%;
    height: auto; 
    background-color: #01969b;
     background-color: rgba(1, 150, 155,0.5);
  
   
  }

  .container1{
    background: none;
  }

  h1{
    padding-left: 0;
    padding-top: 0;

  }
  .logoSjb {
    width: 45px;
    z-index: 2;
    background: none;
    top: 13px;
    left: 2rem;
    margin-top: 0;
    margin-left: 0;
}


  .dflex1{

    width:100vw;
    margin-top:36%;
    background: #01969b;
  }

  .dflex1 li, .dflex1 button{
text-align: left;
margin-top:0;
  }

  a.titreNavSub{
    padding: 0;
  }

  .dflex{
    display: block;
  }

 a.titreNav{
    width:100%;
    margin-left:22%;
    font-size:14px;
    letter-spacing: 0;
  }

  .dflex{
    padding-left: 50px;
  }

  .dflex li, .dflex button a{
    width:100%;
    line-height: 4.2;
    text-align: left;
  }

  .dflex li a.log {
    width: 100%;
    margin-left: 20%;
}

ul.dropdownMenu{
 margin:0; 
  padding:0;
  display: block;
position: unset;
width: 100%;
background: #01969b;
margin-left: 0;
}


a.titreNav{
  width: 100%;
  height: 100%;
display:block;
justify-content: stretch;
margin-left: 22%;
font-size: 14px;
line-height: 4.2;
cursor: pointer;
letter-spacing: 0;
border: none;
border-bottom: 1px solid white
}

span.notreEcole{
  width:56%;
  background-color: #01969b;
}

a.titreNav span.caret, a.titreNav span.caret2{
  width:20%;
}

.styleBurger{
  position: fixed;
}


.btnConnection{
  z-index: 6;
  top: 39px;
  position: fixed;
  left: 2rem;
}

.connectDiv{
  z-index: 6;
  top: 82px;
  left: 0;
}
/*NAV ADMIN*/

.barreNavAbs{
  width:100vw;
  height:100vh;
  position: fixed;
}

.dflexAdmin{
  
  background-color: #01969b;
  border-top: 2px solid white;
  padding-bottom: 1px;
  
}

.dflexCompte{
display:block;
margin-top: 89px;
margin-left: 48px;
}
a.titreNav.noBackg{
  margin-left:0;
  width: 100%;
display: flex;
justify-content: stretch;
margin-left: 22%;
font-size: 14px;
line-height: 4.2;
border-bottom: 1px solid white;
}

a.titreNav.noBackg{
  border-bottom: 1px solid white;
}
.dflexCompte div{
background-color: #01969b;
}

/*COPYRIGHT*/
.copyright p {
  font-size: 9px;
}

/*SCROLL-DOWN*/

.scroll-down{
  height:50px;
  width:30px;
  border:4px solid white;
  position:absolute;
  left:47%;
  bottom:6px;
  border-radius:50px;
  cursor:pointer;

  box-shadow:3px 3px 3px black;
}

.scroll-down::before,
.scroll-down::after{
  content:'';
  position: absolute;
  top:20%;
  left:53%;
  border:3px solid #01969b;
  height:10px;
  width:10px;
  transform: translate(-50%,-100%) rotate(45deg);
  border-top:transparent;
  border-left:transparent;
  animation: scroll-down 1s ease-in-out infinite;
}

.scroll-down::after{
  top:30%;
  animation-delay: 0.3s;
}

@keyframes scroll-down{
  0%{
    opacity:0;
  }
  30%{
    opacity:1;
  }

60%{
  opacity:1;
}

100%{
  opacity:0;
  top:90%;
}
}

/*CONTACT*/

.imgTop{
 /*  display:none; */
 position: relative;
top: -45px;
height: 138px;
}
.imgTop img.imgAccueil{
object-fit: contain;
width: 100%;
height: 100%;
}
.imgTop img.imgAccueil{object-fit: cover;
width: 100%;
height: auto;

}
.styleBurgerDiv{
  background-color: white;
  box-shadow: 3px 3px solid black;
}
.barreLiens {
  width: 100%;
  min-height: 100px;
  background-color: #c35884;
  position: absolute;
  top: 0;
}

.titrePage{
  position: relative;
  margin-top: 0
}

.titrePage h1{
  font-size:25px;
  margin-top: -45px;
padding: 10px;
margin-bottom: 0;
  
}

.titrePage1{
  background-color: white;
  margin-top: -45px;
}

.titrePage1 h1{
  font-size:30px;

}

.aIcon.aIconBorder, .aIcon{
  width: 90%;
  height: 275px;
  background-color: white;
margin: auto;
border-bottom: none;
box-shadow: unset;
margin-bottom: 21px;
border-right: 1px solid #01969b;
}
.aIcon,.aIcon.aIconBorder p{
  font-size: 12px;
  
}

.rond{
  margin-top: 5px;
}


.essaiAbsolute,.essaiAbsolute1,.essaiAbsolute2,.essaiAbsolute3,.essaiAbsolute4{
  display: none;
}

.aIcon-flex1{
  height:auto;
  display:block;
  margin-top: 42px;
}

.footerProject{
  margin-top: 16%;
}

.dflexFooter{
  display: block;
}

.divFooter1{
  display: flex;
}

.ecoleAM{

margin-top:10px;

}

/*PAGE ACCUEIL*/
.aIcon-flex {

  display: block;
}



.tache{
  display:block;
  position: absolute;
top: 486px;
z-index: 0;
}

.guihaut {
  display: block;
  position: absolute;
  width: 44px;
  left: 91%;
  transform: rotate(45deg);
  z-index: 1;
}

.guibas {
  display: block;
  position: absolute;
  width: 44px;
  transform: rotate(230deg);
  z-index: 0;
  top: -15px;
  left: -17px;
}




.carousel{
  display: none;
}

div.carouselAccueil.carousel.slide > div.carousel-inner{
  width: 100%;
}

.note{
  margin-bottom: 34%;
margin-top: 5%;
}

.dflex3{
  display: block;
  margin-top:5%;
}

.accueilFlex{
  width: 95vw;
  display: block;
  margin: auto;
}

.accueilGroupe{
  width: 329px;
  margin: auto;
}

.calendar{
  margin: auto;
}

.barreLiens{
/*   width: 100%;
  height: auto;
  margin-top: 224px;
  background-color: #c35884; */
display: none;
}

.titrePage{
  background-color: rgba(255, 255, 255, 0.5);
}

.imgResponsive{
  position: absolute;
  z-index: 1;
}

.imgResponsive img{
  display: block;
width: 100%;
height: 225px;
object-fit: cover;
margin-top: -46px;
}

.titreMatPrim {
  font-size: 12px;
}

/*PAGE ACCUEIL*/

.aIcon-flex{
  display: none;
}

.philosophie{
  display: block;
  font-size: 12px;
padding: 13px;
position:relative;
border: 1px solid white;
width: 90%;
margin: auto;
margin-top: 34px;
box-shadow: 3px 3px white;
background-color: rgba(1, 150, 155,0.5);
margin-bottom: 20%;
padding-top: 8%;
}

/* .jourSem{
  color: white;
} */

.point1{
  display: none;
}

/*REPAS*/

div.divRepas h4 {
  font-size: 12px;
  padding-top: 5%;
  font-weight: bold;
}

/*NOTRE ECOLE*/

.keyLivret {
  width: 100%;
}

.divTextDirection p,.piscineUl{
  font-size: 12px;
}

.divTextDirection{
  width: 95%;
  margin: auto;
  margin-top: 5%;
}


.piscineLi {
  margin-left: 98px;
}

/*NOS CLASSES*/

.nosClassesP3{
  width: 100%;
  margin-top: 23px;
}

.descClasseGym, .descClasseP1, .descClasseP3, .descClasseP5, .descClasseM1, .descClasseM2, .descClasseNdlr, .descClasseAdaptation, .descClasseGarderie{
  width: 95%;
  box-shadow: none;
  margin-top: 0;
}

.nomProf1,.nomProf{
  position: inherit;
  padding-left: 0;
  font-size: 23px;
  text-align: center;
  padding-top: 13px;
  font-weight: 100;
  
}

.nomProfGarderie{
  position: inherit;
  padding-left: 0;
  font-size: 30px;
  text-align: center;
  padding-top: 13px;
}

.imgPhotoProf10, .imgPhotoProf1, .imgPhotoProf3, .imgPhotoProf5, .imgPhotoProf6, .imgPhotoProf7, .imgPhotoProf8, .imgPhotoProf9, .imgPhotoProfGarderie{
  margin-top: 0;
  margin-left: 0;
  position: inherit;
  border:none;
  border-radius: unset;
  box-shadow: unset;
  margin: auto;
}

.imgPhotoProfGarderie{
  padding-top: 5%;
}


.descClasseP1 p, .descClasseP3 p, .descClasseP5 p, .descClasseM1 p, .descClasseM2 p, .descClasseNdlr p, .descClasseAdaptation p, .descClasseGym p, .descClasseGarderie p{
  font-size: 12px;
  padding-left: 0;
  padding: 10px;
  margin-top: 15px;

}



/*MATERNELLES-PRIMAIRE*/

.ligneDroite2, .ligneDroite{
  display: none;
}

.dflex2{
  mask-position: unset;
  display: flex;
  margin-top: 57px;
}

.dflex2 li{
  border-radius: unset;
}

.active2{
  border-radius: unset;
border:1px solid white;
background: #eb6366;

width: 100%;
height: 100%;
font-size: 12px;
transform: unset;
color: white;
font-weight: bolder;
box-shadow:unset;
padding-top: 39%;
}

.active3{
  border-radius: unset;
  border:1px solid white;
background: #8f4999;

width: 100%;
height: 100%;
font-size: 12px;
transform: unset;
color: white;
font-weight: bolder;
box-shadow:unset;
padding-top: 39%;
}

.active4{
  border-radius: unset;
  border:1px solid white;
background: #ffe861;
width: 100%;
height: 100%;
font-size: 12px;
transform: unset;
color: white;
font-weight: bolder;
box-shadow:unset;
padding-top: 39%;
}

.active5{
  border-radius: unset;
  border:1px solid white;
background: #34c924;

width: 100%;
height: 100%;
font-size: 12px;
transform: unset;
color: white;
font-weight: bolder;
box-shadow:unset;
padding-top: 39%;

}

.active6{
  border-radius: unset;
  border:1px solid white;
background: #df1d25;

width: 100%;
height: 100%;
font-size: 12px;
transform: unset;
color: white;
font-weight: bolder;
box-shadow:unset;
padding-top: 39%;
}

/*ACTIVITES*/

.flexactivite {
  display: block;
}

.dateActivite{
  writing-mode: unset;
  width: 100%;
  text-align: right;
  display: block;
  text-shadow: 1px 1px 2px #c35884;
}

.imgActivitePermanente{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.imgActivitePermanente img{
  width: 100%;
}

.searchActiviteForm{
  width: 310px;
}

/*FOOTER*/

.divUpArrow{
/*   position: fixed;
top: 50%;
left: 334px;
z-index: 2; */
display: none;
}

.divFooter p, .divFooter h4, .divFooter1 h4{
font-size: 13px;
}

.divFooter h4, .divFooter1 h4{
  line-height: unset;
  font-size: 14px;
  }

  .ulLiensFooter{
    display: flex;
    align-items: center;
  }

  .ecoleAM {
    margin-top: 0;
}

.noteDesc p{
  font-size: 13px;
}

/*ADMIn*/

.dflexCompte1{
  display: block;
  width: 100vw;
height: auto;
box-shadow: unset;
border-bottom: none;
}

.dflexCompte1 li.liCompte{
  text-align: left;
}
.dflexAdmin{
  background: none;
  padding-bottom: 0;
}

li.liCompte.titreNav{
  text-align: left;
font-size: 13px;
}



.divAddActivite{
  display: block;
  width: 100%;
}

div.componentActivite.add {
  width: 95%;
}

.formAdd {
  width: 100%;
}

.searchActivites {
  position: unset;
  width: 100%;
  height: auto;
  margin-top: 0;
}
.BgActivitesList{
  padding-top: 0;
  padding-bottom: 0;
}

.titrePrimaire1{
  position: unset;
  padding-bottom: 0;
  font-size: 28px;
}

a.titreNav.noborder{
  border-bottom: none;
}

.titreTextActivitePermanente2{
  width: 31%;
  height: 43px;
  display: flex;
  align-items: center;
}

 .btncancel1{
  display: none;
}

.titrePrimaire{
  top: 59px;
  font-size: 28px;
  font-family: "HVD_Comic_Serif_Pro";
}

.primaire{
  margin-top: 0;
}

/*livret*/

.albumDflex {
  width: 100%;
}

.blockLivret {
  width: 90%;
 
}

.h2Galerie{
font-size: 16px;
font-weight: bold;
}

.totAlbumDflex{
  display: block;
}

.dflexTotMapGalerie {
  width: 100%;
}

.dflexImgPermanente1{
  display: block;
}

div.carouselGaleriePhoto.carousel.slide {
  display: block;
  width: 100%;


}

.btncancel2{
  width: 40px;
  right: 15px;
}

a.carousel-control-next, a.carousel-control-prev{
  width: 30px;
  height: 30px;
  margin: 0;
}
.cancel {
display: none;
  
}


  
  .container1 {
  width: 100%;
  min-height: 100vh;
  margin: auto;
  padding-top: 45px;
  overflow-y:hidden;
}

.caret2{
  background: bottom;
color:black;
  margin-left: 46px;
}

  .styleBurger{
  display:block;
  position: absolute;
  right: 2rem;
  top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;


}  

.styleBurger:focus {
    outline: none;
  }
  .styleBurgerDiv {
    width: 2rem;
    height: 0.25rem;

    position: relative;
    transform-origin: 1px;
   

  }




.dflex{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index:6;
  padding-left: 0;
  top:0;
}
.dflex1{
  height: auto;
display: block;
text-align: left;
position: absolute;
top: 0;
left: 0;
transition: transform 0.3s ease-in-out;
background:none;
}

.dflex1 li{
  margin-top:115px;
  background:none;
}

.titreNav { 
  width: 100%;
  height: 100%;
  display:block;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.2rem;
  color: #0D0C1D;
  text-decoration: none;
  transition: color 0.3s linear;
  background:none;
 
  }

.active{
color:white;
background:none;
}

 .montre{
  display:block;
}
.cache{
  display:none;
} 

.profGard{
  display: block;
}



}

